const TRACKING = Object.freeze({
  //

  gaProd: Object.freeze({
    // salonId, Google Analytics 4 ID
    3292: 'G-TYKC7MM5WY', // old ID: 'UA-19437454-3'
    818: 'G-N93XV1NHZV',
    4876: 'G-ZFVWZ1DHK3',
  }),

  gaStage: Object.freeze({
    // salonId, Google Analytics 4 ID
    12259: 'G-N93XV1NHZV',
    6438: 'UA-113343899-4',
    4876: 'G-ZFVWZ1DHK3',
  }),

  //
});

export default TRACKING;
